import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Home(props) {
    const [certId, setCertId] = useState("")
    let navigate = useNavigate()


    const handleCertIdValidation = (e) => {
        
            navigate(`/content/${certId}`)
       
    }

    return (
       <div className="p-0 m-0 bg-stone-900">
        <ToastContainer
       position="top-center"
       autoClose={2000}
       hideProgressBar={false}
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       theme="light"
   /> <div className='flex flex-row w-screen lg:h-screen h-full items-center justify-around bg-stone-900 bg flex-wrap px-10'>
            
            <div className="flex flex-col lg:w-2/4 mt-10">
                <div className='text-slate-50 items-center justify-center flex flex-col'> <h1 className='text-center text-3xl font-bold '>Excellentway<br></br>englishacademy.com</h1>
                    <h1 className='text-center text-2xl  w-4/5 my-10 '>"Education is the passport to the future, for tomorrow belongs to those who prepare for it today."</h1>
                </div>


            </div>
            <div className="flex flex-col justify-center item-center bg-gray-700 px-10 py-20 rounded mb-20 lg:m-0">
                <h2 className="text-center text-slate-50">Enter your certificate ID</h2> <input type={"text"} className="text-lg w-auto h-12 rounded m-5 pl-2 " onChange={(e) => { setCertId(e.target.value) }} value={certId} />
                <button className='bg-purple-900 px-5 py-2 w-auto rounded text-slate-50 hover:bg-purple-600 active:bg-purple-800 duration-200' onClick={() => { handleCertIdValidation() }}>Search</button>
                </div>

        </div>
        </div>
    )
}