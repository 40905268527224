import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";



export default function RowCard ({data, HostUrl}){

    let navigate = useNavigate()


    function handleEdit(id){

        navigate(`/edit/${id}`)
    }
    return(
        <div className="flex flex-row bg-gray-600 text-slate-50 justify-between items-center mx-5 my-1  p-2 rounded  ">
           
           
            <h1 className="font-bold w-20">{data.certid}</h1>
            <h1 className="w-72">{data.stdname}</h1>
           
            <h1 className="w-20">{data.link1}</h1>
            <h1 className="w-20">{data.link2}</h1>
            <button className="bg-green-600 px-5 py-1 rounded hover:bg-green-500 active:bg-green-900" onClick={()=>{handleEdit(data.certid)}}>Edit</button>
        </div>
    )
}