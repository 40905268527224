import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AddNew({ HostUrl }) {
    let navigate = useNavigate()
    const [certId, setCertId] = useState()
    const [name, setName] = useState()
    const [vid1, setVid1] = useState()
    const [vid2, setVid2] = useState()

    useEffect(()=>{
        if( localStorage.getItem("loginStatus")=="false" || localStorage.getItem("loginStatus")===null)
        {
            navigate("/")
        }
    },[])
    function handleInsert() {
        let formData = new FormData();
        formData.append("certId", certId);
        formData.append("stdname", name);
        formData.append("vidid1", vid1);
        formData.append("vidid2", vid2);

        fetch(HostUrl + "addnew.php", {
            method: "POST",
            body: formData,
    })
            .then(res => res.json())
            .then(data => ValidateResponse(data))
            
    }

    function ValidateResponse(response){
        setCertId("")
        setName("")
        setVid1("")
        setVid2("")
        if(response==true)
        {
            toast.success("Data inserted successfully")
            navigate("/dashboard")
            
        }
        else
        {
            toast.error("The Certificate ID is already exist")
        }
    }
    return (
        <div className="flex flex-row bg h-screen p-3 w-screen items-center justify-center">
            
            <ToastContainer
       position="top-center"
       autoClose={2000}
       hideProgressBar={false}
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       theme="light"
   />

            <div className="flex flex-col  h-full  justify-center">
            <button className="self-start rounded text-blue-500 mb-3 hover:text-blue-300" onClick={()=>{navigate("/dashboard")}}> Go Back</button>
                
                <h1 className="text-slate-50 text-2xl mb-5">Insert Data</h1>
                <p className="text-slate-50">Certificate ID:</p>
                <input type={"text"} className="w-96 rounded mb-5 p-2 " value={certId} onChange={(e) => { setCertId(e.target.value) }} />

                <p className="text-slate-50">Name:</p>
                <input type={"text"} className="w-96 rounded mb-5 p-2" value={name} onChange={(e) => { setName(e.target.value) }} />


                <p className="text-slate-50">Video 1 ID:</p>
                <input type={"text"} className="w-96 rounded mb-5 p-2" value={vid1} onChange={(e) => { setVid1(e.target.value) }} />

                <p className="text-slate-50">Video 2 ID:</p>
                <input type={"text"} className="w-96 rounded mb-5 p-2" value={vid2} onChange={(e) => { setVid2(e.target.value) }} />

                <button className="bg-green-600 rounded text-slate-50 py-2 mb-2 hover:bg-green-500 active:bg-green-900" onClick={()=>{handleInsert()}}> Save</button>
                <button className="bg-red-600 rounded text-slate-50 py-2 hover:bg-red-500 active:bg-red-900"> Delete</button>


            </div>

        </div>
    )
}