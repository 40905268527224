import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Content from './pages/Content';
import Dashboard from './pages/Admin/Dashboard';
import Edit from './pages/Admin/Edit';
import AddNew from './pages/Admin/AddNew';


function App() {

  const HostUrl = "https://excellentwayenglishacademysl.com/api/";
  return (
    <BrowserRouter>
    <Routes>
     
        <Route path="/" element={<Home HostUrl={HostUrl}/>} />
        <Route path="/content/:id" element={<Content HostUrl={HostUrl}/>} />
        <Route path="/dashboard" element={<Dashboard HostUrl={HostUrl}/>} />
        <Route path="/edit/:id" element={<Edit HostUrl={HostUrl}/>} />
        <Route path="/add" element={<AddNew HostUrl={HostUrl}/>} />
      
    
    </Routes>
  </BrowserRouter>

   
  );
}

export default App;
