import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate, Form } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function Content({ id, HostUrl }) {
    let params = useParams();
    var navigate = useNavigate()
    const [content, setContent] = useState([{ name: "Loading", certid: 0, link1: "", link2: "" }])

    function validate(contentArray) {
        if (contentArray.length == 0) {
            toast.error("Oops! We cannot find such certificate ID. Please try again")
            navigate("/")
        }
        else {
            setContent(contentArray)
        }

    }

    useEffect(() => {
        let formData = new FormData();
        formData.append("certId", params.id)
        fetch(HostUrl+"getContent.php", {
            method: "POST",
            body: formData,

        })
            .then(res => res.json())
            .then(data => validate(data))



    }, [])
    console.log(content)

    return (


        <div className='flex flex-col w-full h-auto min-h-screen items-center justify-start bg-stone-900 bg p-5'>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                />
            <button className='bg-purple-900 px-5 py-2 w-auto rounded text-slate-50 hover:bg-purple-600 active:bg-purple-800 duration-200 self-start mb-3 ' onClick={() => { navigate("/") }}>Back</button>
            <div className="bg-gray-800 p-3 rounded w-4/5 ">  <h1 className="text-slate-50 text-2xl"> Student Name: {content[0].stdname}</h1>
                <h2 className="text-slate-50 text-xl">Your certificate ID: {params.id}</h2></div>



            <div className="flex flex-row flex-wrap lg:flex-nowrap  items-center justify-center  mt-5 lg:h-screen w-full px-10">

                <iframe src={`https://www.youtube.com/embed/${content[0].link1}`} frameborder='0' allowfullscreen allow="fullscreen;"
                    className=" w-full lg:w-11/12  h-72 lg:h-full m-3"></iframe>
                <iframe src={`https://www.youtube.com/embed/${content[0].link2}`} frameborder='0' allowfullscreen allow="fullscreen;"
                    className="w-full lg:w-11/12  h-72 lg:h-full m-3"></iframe>

            </div>






        </div>
    )
}